<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Bay Done") }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
      class="text-center"
    >
      <img src="../assets/sccess.png" alt="" v-if="done == 'success'" />
      <img src="../assets/error.webp" alt="" v-else />
    </section>
  </div>
</template>
<script>
import axios from "axios";
import querystring from "querystring";

export default {
  data() {
    return {
      screen: null,
      id: null,
      done: null,
    };
  },
  methods: {
    httpg() {
      return axios.create({
        baseURL: `https://eu-prod.oppwa.com/v1/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer OGFjOWE0Y2E4MzgzNTA5YjAxODM4M2ZjMmE2MDExY2Z8cDhHTWhuWXFiWA==`,
        },
      });
    },
    updateDataById() {
      this.httpg()
        .get(
          `checkouts/${this.id}/payment?entityId=8ac9a4ca8383509b018383fdcc5811ec`
        )
        .then((res2) => {
          const paymentCCdon = res2.data;

          var code = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
          let c = code.test(paymentCCdon?.result?.code);
          const paymentCCdone = c ? true : false;
          const paymentCCdoneDescription = paymentCCdon?.result?.description;
          this.$socket.emit("send", {});
          this.$http
            .post(`appointments/updateMany/${this.id}`, {
              paymentDone: paymentCCdone,
              paymentCCdone,
              paymentCCdoneDescription,
            })
            .then((res) => {});
        });
    },
    updateGiftById() {
      const i = this.$route.query.i;
      this.httpg()
        .get(`checkouts/${i}/payment?entityId=8ac9a4ca8383509b018383fdcc5811ec`)
        .then((res2) => {
          const paymentCCdon = res2.data;

          var code = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
          let c = code.test(paymentCCdon?.result?.code);
          const paymentCCdone = c ? true : false;
          const paymentCCdoneDescription = paymentCCdon?.result?.description;

          this.$http
            .put(`gifts/${this.id}`, {
              paymentCCID: i,
              paymentDone: paymentCCdone,
              paymentCCdone,
              paymentCCdoneDescription,
            })
            .then((res) => {});
        });
    },
    updatePackageById() {
      const i = this.$route.query.i;
      this.httpg()
        .get(`checkouts/${i}/payment?entityId=8ac9a4ca8383509b018383fdcc5811ec`)
        .then((res2) => {
          const paymentCCdon = res2.data;

          var code = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
          let c = code.test(paymentCCdon?.result?.code);
          const paymentCCdone = c ? true : false;
          const paymentCCdoneDescription = paymentCCdon?.result?.description;

          this.$http
            .put(`packages_users/${this.id}`, {
              paymentCCID: i,
              paymentDone: paymentCCdone,
              paymentCCdone,
              paymentCCdoneDescription,
            })
            .then((res) => {});
        });
    },
    updateCartById() {
      const i = this.$route.query.i;
      this.httpg()
        .get(`checkouts/${i}/payment?entityId=8ac9a4ca8383509b018383fdcc5811ec`)
        .then((res2) => {
          const paymentCCdon = res2.data;

          var code = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
          let c = code.test(paymentCCdon?.result?.code);
          const paymentCCdone = c ? true : false;
          const paymentCCdoneDescription = paymentCCdon?.result?.description;

          this.$http
            .put(`carts/${this.id}`, {
              paymentCCID: i,
              paymentDone: paymentCCdone,
              paymentCCdone,
              paymentCCdoneDescription,
            })
            .then((res) => {});
        });
    },
  },
  created() {
    this.screen = this.$route.params.screen;
    this.id = this.$route.params.id;
    this.done = this.$route.params.done;
    if (this.screen == "massageorder" && this.done == "success") {
      this.updateDataById();
    }

    if (this.screen == "gift" && this.done == "success") {
      this.updateGiftById();
    }
    if (this.screen == "package" && this.done == "success") {
      this.updatePackageById();
    }
    if (this.screen == "cart" && this.done == "success") {
      this.updateCartById();
    }
    // if (this.screen == 'massageorder') {
    // }
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>
